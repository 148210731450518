<template>
	<section class="activeSponsorsSection" id="activeSponsorsSection">
		<h1>Sponsors</h1>
		<div class="activeSponsorsContainer">
			
<div class="platinum">
				<h3>Platinum</h3>
						<div>
					<a target="_blank" href="https://www.hivedigitaltechnologies.com/">
						<img src="@/assets/images/hive.png" alt="hive digital technologies" />
					</a>
				</div>
			</div>
			<div class="silver">
				<h3>Silver</h3>
						<div>
					<a target="_blank" href="https://rootstock.io/">
						<img src="@/assets/images/rootstock-logo.png" alt="Rootstock" />
					</a>
					<a target="_blank" href="https://www.blockscout.com/">
						<img src="@/assets/images/blockscout-logo.png" alt="Blockscout" />
					</a>
			<a target="_blank" href="https://citrea.xyz/">
						<img src="@/assets/images/citrea-logo_white.png" alt="citrea" />
					</a>
	<a target="_blank" href="https://evertas.com/">
						<img src="@/assets/images/evertas-logo.svg" alt="evertas" />
					</a>
				</div>
			</div>
		</div>
	</section>
</template>
